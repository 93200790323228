<template>
  <v-form>
    <v-card>
      <v-card-title class="primary white--text">入力確認</v-card-title>
      <v-card-text class="mt-4">
        <v-row justify="center">
          <v-col>
            <v-data-table
              :items="currentItem"
              :headers="headers"
              hide-default-header
              hide-default-footer
              class="elevation-1"
            >
              <template v-slot:[`item.itemValue`]="{ item }">
                <div style="white-space: pre;">{{ item.itemValue }}</div>
              </template>
              <template v-slot:[`item.inputValue`]="{ item }">
                <div style="white-space: pre;">{{ item.inputValue }}</div>
              </template>
              <template v-slot:[`item.inputInfo`]="{ item }">
                <div class="error--text">{{ item.inputInfo }}</div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn color="primary darken-1" text @click="back">戻る</v-btn>
        <v-btn color="warning darken-1" text @click="saveCurrentItem">{{ mode == 'edit' ? '更新' : '登録' }}を完了する</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>

import dateformat from 'dateformat';

export default {
  name: 'PatientRegisterConfirm',
  props: {
    item: Object,
    mode: {
      type: String,
      default: 'none'
    },
    status: Number,
    beforeAnalysisOpinionType: {
      type: Number,
      default: null
    },
    isContractedAnalysisOption: {
      type: Boolean,
      default: null
    },
  },
  data() {
    return {
      headers: [
        { text: '項目', value: 'itemValue'},
        { text: '入力値', value: 'inputValue'},
        { text: '', value: 'inputInfo'},
      ],
      currentItem: this.createEmptyCurrentItem(),
      ANALYSIS_OPINION_TYPES: {
        AUTO: 0,
        TECHNICIAN: 1,
        DOCTOR: 2,
      },
      analysisOpinionTypeLabels: ['自動解析', '技師解析', '医師所見'],
    }
  },
  computed: {
    isTechCp2207() {
      return 'is_tech_cp_2207' in this.$store.state.configs && this.$store.state.configs.is_tech_cp_2207 === true ? true : false;
    },
  },
  methods: {
    back() {
      this.$emit('reenter');
    },
    async saveCurrentItem() {
      let isUpdate = false;
      let orgsRef = this.$db.doc(`customer_orgs/${this.$store.state.user.org_id}`);
      let promise = this.$db.runTransaction((t) => {
        return t.get(orgsRef)
          .then(async(doc) => {

            if (this.item.examNumber != undefined && this.item.examNumber != null && this.item.examNumber != '') {
              // 検査番号が登録済み = 更新処理
              isUpdate = true;
              return {
                transaction: t,
                generatedExamNumber: this.item.examNumber
              };
            }

            // 検査番号が未登録だった場合、採番して登録する
            if (!doc.exists) {
              return Promise.reject('対象の医療機関が存在しません。');
            }
            let data = doc.data();
            if (data.current_exam_number == undefined || data.current_exam_number == null) {
              data = {current_exam_number: dateformat(new Date(), 'mmdd') + "-" + "0"};
            }
            let generatedExamNumber = '';
            const latestExamNumberParts = data.current_exam_number.split("-");
            if (dateformat(new Date(), 'mmdd') == latestExamNumberParts[0]) {
              const incrementedNumber = parseInt(latestExamNumberParts[1]) + 1;
              generatedExamNumber = `${latestExamNumberParts[0]}-${incrementedNumber}`
            } else {
              generatedExamNumber = `${dateformat(new Date(), 'mmdd')}-1`;
            }
            let updateData = {
              current_exam_number: generatedExamNumber,
            };
            await t.update(orgsRef, updateData);
            return {
              transaction: t,
              generatedExamNumber: generatedExamNumber
            };
          })
          .then(async (result) => {
            const t = result.transaction;
            this.item.examNumber = result.generatedExamNumber

            // デバイスデータ取得
            const deviceDoc = await this.$db.collection('devices').doc(`T-${this.item.deviceId}`).get();
            // if (!deviceDoc.exists) return Promise.reject('対象の心電計が登録されていません。');
            const deviceData = deviceDoc.data();

            // 組織IDを追加
            this.item['org_id'] = this.$store.state.user.org_id;

            // 解析オプションが変更されている場合記録する
            if (this.isContractedAnalysisOption && this.beforeAnalysisOpinionType != this.item.analysis_opinion_type) {
              this.item['anarysis_opinion_type_change_history'] = {
                datetime: this.$firebase.firestore.FieldValue.serverTimestamp(),
                type: this.item.analysis_opinion_type,
                name: this.$store.state.user.name,
              }
            } else {
              delete this.item['anarysis_opinion_type_change_history'];
            }

            if (deviceData == undefined) {
              // 新規患者登録
              await this.addPatient(t, this.item);
            } else if(deviceData.patient_id == null){
              // 患者未登録で受付した場合
              await this.addPatientAndUpdateDevice(t, this.item);
            } else {
              // 患者更新
              await this.updatePatient(t, this.item, deviceData.patient_id);
            }
            return {
              transaction: t,
              deviceData: deviceData
            };
          })
          // 解析オプション変更対応
          .then(async (result) => {
            const t = result.transaction;
            const deviceData = result.deviceData

            // 解析完了状態でない、又は解析オプションが変更されていない、又は解析オプションが自動解析の場合は終了
            if (
              this.status != 7
              || this.beforeAnalysisOpinionType == this.item.analysis_opinion_type
              || this.item.analysis_opinion_type == this.ANALYSIS_OPINION_TYPES.AUTO
            ) return t;
            // 状態を解析中に戻す
            t.update(this.$db.collection('devices').doc(`T-${this.item.deviceId}`), {
                status: 6,
                analyzed: null,
                modified: this.$firebase.firestore.FieldValue.serverTimestamp(),
            });
            // My holter側の状態を変更
            let reData = await this.$functions.registerMyholterLoginRequest({
              analysis_id: deviceData.analysis_id,
              device_id: deviceData.device_id
            });
            if (!('data' in reData) || reData.data.result != 'OK') {
              let errMsg = 'data' in reData && 'errors' in reData.data
                ? JSON.stringify(reData.data.errors)
                : 'registerMyholterLoginRequest failed';
              return Promise.reject(errMsg);
            }

            let myholterUrl = this.$store.state.configs.my_holter_url;
            if (myholterUrl.slice(-1) != '/')  myholterUrl = myholterUrl + '/';

            const token = await this.$auth.currentUser.getIdToken(true);
            const formData = new FormData();
            formData.append('id', reData.data.id);
            formData.append('token', token);
            formData.append('device_id', deviceData.device_id);
            formData.append('analysis_id', deviceData.analysis_id);
            formData.append('opinion_type', this.item.analysis_opinion_type);
            const response = await fetch(
              myholterUrl + 'patch-ecgs/change-opinion',
              {
                method: 'POST',
                body: formData,
              }
            )

            if (!response.ok) return Promise.reject('レスポンスステータス = ' + response.status);
            const json = await response.json();
            if (!('result' in json) || json['result'] != 'OK'){
              return Promise.reject(JSON.stringify(json));
            }

            // 担当医へメール送信（エラーが発生したとしても、My holterが更新済みのため正常でとおす）
            try {
              await this.$functions.sendMailChangedAnalysisOpinionType({
                device_id: deviceData.device_id,
                opinion_type: this.item.analysis_opinion_type,  // まだ更新されていないため変更後の値をパラメータで渡す
              });
            } catch (e) {
              console.log(e)
            }

            return t;
          });
      });
      try {
        await this.$root.$progressive(promise);
        if (isUpdate) {
          this.$root.$alert('完了', '更新が完了しました。');
          this.$emit('close', true);
        } else {
          this.$emit('complete', this.item);
        }

        if (
          this.beforeAnalysisOpinionType != this.item.analysis_opinion_type
          && !(this.isTechCp2207 && !this.isContractedAnalysisOption)   // 22/07 技師解析CPに対する一時的な対応
        ) {
          // 解析オプションが変更されている場合、操作記録
          this.$functions.log({
            tags: ['analysis_opinion_type', 'change'],
            message: `T-${this.item.deviceId}：${this.analysisOpinionTypeLabels[this.beforeAnalysisOpinionType]} → ${this.analysisOpinionTypeLabels[this.item.analysis_opinion_type]}：解析オプションを変更しました。`
          });
        }

        return;
      } catch (e) {
        console.log(e)
        this.$root.$alert('エラー', '患者情報の登録/更新に失敗しました。');
        this.$emit('close', false);
        return;
      }
    },
    async addPatient(t, item) {
      const patientRef = this.$db.collection('patients').doc();
      const patientId = patientRef.id;
      const now = this.$firebase.firestore.FieldValue.serverTimestamp();

      const addPatientData = {
        customer_staff_id_list: this.getCustomerStaffsIdList(item.customerStaffs),
        age: item.age == null || item.age == '' ? null : parseInt(item.age),
        sex: parseInt(item.sex),
        patient_initial: item.patientInitial,
        exam_number: item.examNumber,
        created: now,
        org_id: item.org_id,
        analysis_opinion_type: item.analysis_opinion_type,
      };

      if (item.anarysis_opinion_type_change_history) {
        // 解析オプション変更履歴がある場合は更新対象とする
        addPatientData['anarysis_opinion_type_change_history'] = item.anarysis_opinion_type_change_history;
      }

      await t.set(patientRef, addPatientData);
      const holterId = `T-${this.item.deviceId}`;
      const deviceRef = this.$db.doc(`devices/${holterId}`);

      let orgsRef = this.$db.doc(`customer_orgs/${item.org_id}`);
      let orgsDoc = await orgsRef.get();
      const orgsData = await orgsDoc.data();
      const reception_center_id = orgsData.reception_id;

      const addDeviceData = {
        status: 4,
        type: 'holter',
        analysis_id: null,
        analyzed: null,
        arrived: null,
        arrived_staff_id: null,
        center_id: null,
        collected: null,
        device_collected: null,
        device_collected_staff_id: null,
        device_upload_error_info: null,
        device_uploaded: null,
        device_uploaded_staff_id: null,
        disposed: null,
        disposed_staff_id: null,
        is_lost: null,
        keeped: null,
        log_collected: null,
        log_collected_staff_id: null,
        log_uploaded: null,
        log_uploaded_staff_id: null,
        ec_order_id: null,
        order_org_id: item.org_id,
        patient_attached_staff_id: null,
        received: null,
        received_staff_id: null,
        received2: now,
        received2_staff_id: null,
        reception_center_id: reception_center_id,
        remarks: '',
        shipped: null,
        shipped_staff_id: null,
        created: now,
        modified: now,
        deleted: null,
        patient_id: patientId,
        patient_attached: new Date(this.item.patientAttached),
        device_id: holterId,
        produced: null,
        expiration_date: null,
      };
      await t.set(deviceRef, addDeviceData);

      // デバイス保有数の更新
      const holter_stock_quantity = orgsData.holter_stock_quantity;
      let holterStockQuantity = holter_stock_quantity == undefined || holter_stock_quantity == null ? 0 : parseInt(holter_stock_quantity);
      let updateInventoryData = {
        holter_stock_quantity: holterStockQuantity + 1,
        modified: this.$firebase.firestore.FieldValue.serverTimestamp()
      };

      // 更新
      t = await t.update(orgsRef, updateInventoryData);

      // 操作記録
      this.$functions.log({
        tags: ['patient', 'new'],
        message: `${holterId}：検査番号=${item.examNumber}：患者情報を登録しました。`
      });
    },
    async addPatientAndUpdateDevice(t, item) {
      const patientRef = this.$db.collection('patients').doc();
      const patientId = patientRef.id;
      const now = this.$firebase.firestore.FieldValue.serverTimestamp();

      const addPatientData = {
        customer_staff_id_list: this.getCustomerStaffsIdList(item.customerStaffs),
        age: item.age == null || item.age == '' ? null : parseInt(item.age),
        sex: parseInt(item.sex),
        patient_initial: item.patientInitial,
        exam_number: item.examNumber,
        created: now,
        org_id: item.org_id,
        analysis_opinion_type: item.analysis_opinion_type,
      };

      await t.set(patientRef, addPatientData);

      const holterId = `T-${this.item.deviceId}`;
      const deviceRef = this.$db.doc(`devices/${holterId}`);

      t.update(deviceRef, {
        modified: now,
        order_org_id: item.org_id,
        patient_attached: new Date(item.patientAttached),
        patient_id: patientId,
      });
    },
    async updatePatient(t, item, patientId) {
      const now = this.$firebase.firestore.FieldValue.serverTimestamp();
      const patientRef = this.$db.doc(`patients/${patientId}`);

      // 操作記録のための更新前データ取得
      let beforeUpdate = await patientRef.get();
      beforeUpdate = beforeUpdate.data();

      const updateData =  {
        customer_staff_id_list: this.getCustomerStaffsIdList(item.customerStaffs),
        age: item.age == null || String(item.age).trim() == '' ? null : parseInt(item.age),
        sex: parseInt(item.sex),
        patient_initial: item.patientInitial,
        exam_number: item.examNumber,
        modified: now,
        org_id: item.org_id,
        analysis_opinion_type: item.analysis_opinion_type,
      };

      if (item.anarysis_opinion_type_change_history) {
        // 解析オプション変更履歴がある場合は更新対象とする
        updateData['anarysis_opinion_type_change_history'] = item.anarysis_opinion_type_change_history;
      }

      t.update(patientRef,updateData);
      const holterId = `T-${this.item.deviceId}`;
      const deviceRef = this.$db.doc(`devices/${holterId}`);

      // 操作記録のための更新前データ取得
      let tmp = await deviceRef.get();
      tmp = tmp.data();
      beforeUpdate['patient_attached'] = dateformat(new Date(tmp.patient_attached.seconds * 1000), 'yyyy/mm/dd HH:MM:ss');

      t.update(deviceRef, {
        modified: now,
        order_org_id: item.org_id,
        patient_attached: new Date(item.patientAttached)
      });

      // 操作記録
      this.$functions.log({
        tags: ['patient', 'update'],
        message: `${holterId}：検査番号=${item.examNumber}：患者情報を更新しました。`,
        item: { before: beforeUpdate, after: this.item }
      });
    },
    getCustomerStaffsIdList(customerStaffs) {
      const customerStaffsIdList = []
      customerStaffs.forEach((staff) => {
        customerStaffsIdList.push(staff.id);
      });
      return customerStaffsIdList;
    },
    createCurrentItem(item) {
      const patientAttached = item.patientAttached ? dateformat(new Date(item.patientAttached), 'yyyy/mm/dd HH:MM:ss'): '';

      let patientAttachedInfo = /^[A-Z][0-9A-Z]{6}$/.test(item.deviceId) && this.status < 7
        ? '※ 心電図データ解析後に自動で開始日時が反映されます'
        : '';

      let customerStaffNamesString = '';
      this.item.customerStaffs.forEach((staff, index) => {
        if (index > 0) {
          customerStaffNamesString += '\r\n'
        }
        customerStaffNamesString += staff.name;
      });

      let sex = '';
      switch (this.item.sex) {
        case 1:
          sex = '男';
          break;
        case 2:
          sex = '女';
          break;
      }

      // const analysisOpinionType = this.analysisOpinionTypeLabels[this.item.analysis_opinion_type];
      // let analysisOpinionTypeInfo = this.item.analysis_opinion_type == 0
      //   ? ''
      //   : '※「技師解析」又は「医師所見」が選択されているため、解析時に追加料金が発生します';

      // 22/07 技師解析CPに対する一時的な対応
      // if (this.isTechCp2207 && !this.isContractedAnalysisOption) analysisOpinionTypeInfo = '';

      return [
        { itemValue: 'シリアル番号', inputValue: item.deviceId},
        { itemValue: '検査開始時刻', inputValue: patientAttached, inputInfo: patientAttachedInfo },
        { itemValue: '性別', inputValue: sex },
        { itemValue: '年齢', inputValue: this.item.age },
        { itemValue: '患者イニシャル', inputValue: this.item.patientInitial },
        // { itemValue: '解析/所見', inputValue: analysisOpinionType, inputInfo: analysisOpinionTypeInfo},
        { itemValue: '担当医', inputValue: customerStaffNamesString },
      ]
    },
    createEmptyCurrentItem() {
      return [
        { itemValue: 'シリアル番号', inputValue: '' },
        { itemValue: '検査開始時刻', inputValue: '' },
        { itemValue: '性別', inputValue: '' },
        { itemValue: '年齢', inputValue: '' },
        { itemValue: '患者イニシャル', inputValue: '' },
        // { itemValue: '解析/所見', inputValue: '' },
        { itemValue: '担当医', inputValue: '' },
      ];
    }
  },
  mounted() {
    console.log('PatientRegisterConfirm mounted');
    let currentItem = this.createEmptyCurrentItem();
    if (this.item != null) {
      currentItem = this.createCurrentItem(this.item);
    }
    this.currentItem = currentItem;
  },
  watch: {
    item(value) {
      this.currentItem = this.createCurrentItem(value);
    }
  }
}
</script>