<template>
  <v-form
    v-if="currentItem"
    ref="form"
    lazy-validation
    v-model="valid"
  >
    <v-card>
      <v-card-title class="primary white--text" style="padding-left: 0 !important; padding-right: 0 !important;">
        <v-container class="pt-0 pb-0">患者様情報の{{ mode == 'edit' ? '更新' : '登録' }}</v-container>
      </v-card-title>
      <v-card-text>

        <v-container v-if="mode == 'new'" class="pb-0 pt-0">
          <div class="text-body-1">検査を開始する患者様の情報と被検者情報記録カードに添付してあるバーコードのシリアル番号を入力してください。</div>
        </v-container>

        <v-container>
          <v-row dense>
            <v-col cols="12" sm="4" class="align-center d-flex">
              <v-text-field
                v-model="currentItem.deviceId"
                label="シリアル番号(必須)"
                ref="deviceIdTextField"
                :rules="rules.deviceId"
                @input="handleInputDeviceId"
                required
                :disabled="mode == 'edit'"
              />
              <v-tooltip bottom color="#666666" max-width="90%">
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot="{ hover }" >
                    <span v-bind="attrs" v-on="on" class="mb-1">
                      <v-icon v-if="hover" dense>mdi-help-circle</v-icon>
                      <v-icon v-else dense>mdi-help-circle-outline</v-icon>
                    </span>
                  </v-hover>
                </template>
                <div class="d-flex align-center justify-center flex-column mt-4 mb-3 ml-2 mr-2">
                  <v-img
                    class="mb-1"
                    max-width="700"
                    width="100%"
                    src="/img/serial-number-example.png"
                  />
                  <div class="text-body-1" style="color: #ffffff;">（入力するシリアル番号の場所）</div>
                </div>
              </v-tooltip>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                v-if="mode == 'edit'"
                v-model="currentItem.examNumber"
                label="検査番号"
                :disabled="true"
              />
            </v-col>
          </v-row>

          <v-row dense>
          <v-col cols="12" sm="4" class="align-center d-flex">
          <v-text-field
                v-if="mode == 'new'"
                v-model="currentItem.password"
                label="パスワード(必須)"
                ref="passwordTextField"
                :rules="rules.password"
                required
                :disabled="mode == 'edit'"
              />
          </v-col>
          <v-col cols="12" sm="3"></v-col>
          </v-row>

          <!-- 再利用する可能性があるためコメントアウトにて対応 -->
          <!-- <div class="mt-4">検査開始日時(必須)</div>
          <div v-if="isNextGenerationEcg"
            class="red--text text-caption"
          >
            ※ 心電図データ解析後に自動で開始日時が反映されます。
          </div>
          <v-row dense>
            <v-col cols="12" sm="4" >
              <v-text-field
                dense
                type="date"
                @change="handleChangeFormatedDate"
                :value="formatedDate"
                :rules="rules.date"
                :disabled="status > 4 || this.isNextGenerationEcg"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                dense
                type="time"
                @change="handleChangeFormatedTime"
                :value="formatedTime"
                :rules="rules.time"
                step="1"
                :disabled="status > 4 || this.isNextGenerationEcg"
              />
            </v-col>
          </v-row> -->

          <v-row dense>
            <v-col cols="12" sm="auto">
              <v-radio-group
                class="mt-1 mr-4"
                v-model="currentItem.sex"
                label="性別"
                row
                :disabled="status > 4"
              >
                <v-radio class="ml-3" key="none" label="無回答" :value="0"></v-radio>
                <v-radio key="man" label="男" :value="1"></v-radio>
                <v-radio key="woman" label="女" :value="2"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="4" sm="3" md="2" lg="1">
              <v-text-field
                dense
                v-model="currentItem.age"
                label="年齢"
                type="number"
                min="0"
                max="200"
                :rules="rules.age"
                :disabled="status > 4"
              />
            </v-col>
          </v-row>

          <v-row dense class="mt-n4">
            <v-col cols="12" class="patient-initial">
              <div class="mr-6 v-input v-input--selection-controls v-input--radio-group">
                <legend class="theme--light v-label">患者イニシャル</legend>
              </div>
              <v-text-field
                v-model="currentItem.patientInitialFirst"
                maxlength="1"
                class="one-digit-charactor first-char"
                ref="patientInitialFirstTextField"
                @compositionstart="handleCompositionStartInitialFirst"
                @compositionend="handleCompositionEndInitialFirst"
                @input="handleInputPatientInitialFirst"
                :disabled="status > 4"
              />
              <v-text-field
                v-model="currentItem.patientInitialSecond"
                maxlength="1"
                class="one-digit-charactor second-char"
                ref="patientInitialSecondTextField"
                @input="handleInputPatientInitialSecond"
                :disabled="status > 4"
              />
              <div class="ml-6 v-input v-input--selection-controls v-input--radio-group">
                <legend class="theme--light v-label">入力例）日東&nbsp;太郎（にっとう&nbsp;たろう）→&nbsp;N&nbsp;T</legend>
              </div>
            </v-col>
          </v-row>

          <!--<v-row class="analysis-opinion-type">
            <v-col cols="12" md="12">

                <v-row dense>
                <v-col cols="12" md="12">
                  <label>
                    解析/所見
                    <span v-if="
                      isContractedAnalysisOption !== true
                      && (
                        mode == 'new'
                        || (
                          mode == 'edit' && [4, 9].includes(status)
                        )
                        || (
                          mode == 'edit' && [7].includes(status) && beforeAnalysisOpinionType == 0
                        )
                      )
                    ">
                      （オプション解析が未契約のため変更できません）
                    </span>
                    <span v-else-if="mode == 'edit' && status == 9 && beforeAnalysisOpinionType == 0">（デバイスが廃棄済みのため変更できません）</span>
                  </label>
                  <div v-if="!isContractedAnalysisOption && isTechCp2207" class="blue--text text-caption">
                    ※ただいま技師解析キャンペーン実施中！<br />※下記の選択は不要です。
                  </div>
                  // 22/07 技師解析CPに対する一時的な対応
                  <div v-else class="red--text text-caption">※「技師解析」もしくは「医師所見」を選択した場合は、解析時に追加料金が発生します。</div>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" md="5">
                  <v-radio-group
                    class=""
                    v-model="currentItem.analysis_opinion_type"
                    row
                    :disabled="isContractedAnalysisOption !== true || (mode=='edit' && ![4, 7].includes(status)) || (mode=='edit' && status==7 && beforeAnalysisOpinionType!=0)"
                    mandatory
                    hide-details
                  >
                    <v-radio label="自動解析" :value="ANALYSIS_OPINION_TYPES.AUTO" />
                    <v-radio label="技師解析" :value="ANALYSIS_OPINION_TYPES.TECHNICIAN" />
                    <v-radio label="医師所見" :value="ANALYSIS_OPINION_TYPES.DOCTOR" />
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="7" v-if="mode=='edit' && isContractedAnalysisOption === true">
                  <div>{{ anarysisOpinionTypeChangeHistory }}</div>
                </v-col>
              </v-row>

            </v-col>
          </v-row>-->

          <v-row v-if="currentItem.doctors && currentItem.doctors.length > 0">
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="currentItem.doctors"
                label="旧バージョンで登録された担当医（※ 表示のみ）"
                readonly
                dense
                hide-details
              />
              <div class="mb-4 mt-1 text-caption">下記の新バージョンの担当医が選択されていない場合、上記の中でシステムに登録されている担当医にのみ解析完了メールを送信します</div>
            </v-col>
          </v-row>

          <div class="mt-6">担当医の選択</div>
          <div class="red--text text-caption mb-4">※ 担当医を選択した場合は、選択した担当医に、解析完了後などにシステムから通知メールが送信されます。</div>
          <v-row
            dense
            v-for="customerStaff in currentItem.customerStaffs"
            :key="customerStaff.index"
          >
            <v-col cols="9" sm="5">
              <v-text-field
                v-if="!isExistInStaffs(customerStaff.id)"
                :value="customerStaff.name"
                :ref="'customerStaffSelect-' + customerStaff.index"
                disabled
                hint="削除されたスタッフのため表示・削除のみ可能です"
                persistent-hint
                dense
              />
              <v-select
                v-else
                v-model="customerStaff.id"
                :items="customerStaffSelectItems"
                :ref="'customerStaffSelect-' + customerStaff.index"
                @input="handleInputCustomerStaffSelect"
                :disabled="status > 6"
                dense
              ></v-select>
            </v-col>
            <v-col cols="3" sm="2" class="align-center justify-start">

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mr-2"
                    x-small
                    fab
                    depressed
                    color="primary"
                    @click="addCustomerStaffForm()"
                    :disabled="status > 6"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>担当医選択欄の追加</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    fab
                    depressed
                    color="error"
                    @click="deleteCustomerStaffForm(customerStaff.index)"
                    :disabled="status > 6"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark>mdi-minus</v-icon>
                  </v-btn>
                </template>
                <span>担当医選択欄の削除</span>
              </v-tooltip>

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container class="d-flex justify-center pt-0">
          <v-btn
            v-if="mode != 'new'"
            color="primary darken-1"
            class="mr-4"
            large
            width="250"
            max-width="40%"
            @click="handleClose">
            閉じる
          </v-btn>
          <v-btn
            v-if="mode == 'new'"
            color="warning darken-1"
            class="mr-4"
            large
            width="500"
            max-width="80%"
            :disabled="!valid"
            @click="confirmCurrentItem"
          >
            登録
          </v-btn>
          <v-btn
            v-if="mode == 'edit'"
            color="warning darken-1"
            class=""
            large
            width="250"
            max-width="40%"
            :disabled="!valid || (status > 6 && isContractedAnalysisOption !== true) || (status > 6 && beforeAnalysisOpinionType != 0)"
            @click="confirmCurrentItem"
          >
            更新
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import dateformat from 'dateformat';

const date2Strings = (dt) => {
  const date = [
    dt.getFullYear(),
    "-",
    (dt.getMonth() + 1).toString().padStart(2, "0"),
    "-",
    dt.getDate().toString().padStart(2, "0")
  ].join("");

  const time = [
    dt.getHours().toString().padStart(2, "0"),
    ":",
    dt.getMinutes().toString().padStart(2, "0"),
    ":",
    dt.getSeconds().toString().padStart(2, "0")
  ].join("");

  return {date, time};
}

export default {
  name: 'PatientRegisterForm',
  props: {
    item: Object,
    mode: {
      type: String,
      default: 'none'
    },
    status: Number,
    orgData: Object,
    beforeAnalysisOpinionType: {
      type: Number,
      default: null
    },
    isContractedAnalysisOption: {
      type: Boolean,
      default: null
    },
    serial: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      valid: true,
      currentItem: null,
      maxCustomerStaffIndex: 0,
      customerStaffSelectItems: [],
      formatedDate: null,
      formatedTime: null,
      rules: {
        deviceId: [
          v => !!v || '入力は必須です。',
          v => /^([0-9]{6}|[A-Z][0-9A-Z]{6})$/.test(v) || '6桁の数字、又は英字(大文字)から始まる7桁の英数字です。'
        ],
        password: [
          v => !!v || '入力は必須です。',
        ],
        sex: [
        ],
        age: [
          v => {
            if (v == null) return true;
            v = String(v);
            if (v.trim() == '') return true;
            if (!this.checkDigit(v)) return '数字を入力してください。';
            if (v < 0 || 200 < v) return '0 から 200 までの数値を入力してください。';
            return true;
          }
        ],
      },
      doctorsForOld: [],
      isCompositionStartInitialFirst: false,
      ANALYSIS_OPINION_TYPES: {
        AUTO: 0,
        TECHNICIAN: 1,
        DOCTOR: 2,
      },
      anarysisOpinionTypeChangeHistory: '',
      isNextGenerationEcg: false,
    }
  },
  computed: {
    isTechCp2207() {
      return 'is_tech_cp_2207' in this.$store.state.configs && this.$store.state.configs.is_tech_cp_2207 === true ? true : false;
    },
  },
  methods: {
    /**
     * event取得用
     */
    handleClose() {
      console.log('PatientRegisterForm handleClose');
      this.$emit('close');
      this.currentItem = null;
    },
    handleInputDeviceId() {
      this.checkDeviceId();
    },
    handleInputCustomerStaffSelect() {
      this.checkDuplicateCustomerStaff();
    },
    handleInputPatientInitialFirst() {
      const initial = this.currentItem.patientInitialFirst;
      const result = this.checkPatientInitialDigit(initial, this.$refs.patientInitialFirstTextField);
      if (result && !this.isCompositionStartInitialFirst) {
        // 半角英字、かつIME変換が完了していた場合
        this.$refs.patientInitialSecondTextField.focus();
      }
    },
    handleCompositionStartInitialFirst() {
      this.isCompositionStartInitialFirst = true;
    },
    handleCompositionEndInitialFirst() {
      this.isCompositionStartInitialFirst = false;
      this.handleInputPatientInitialFirst();
    },
    handleInputPatientInitialSecond() {
      const initial = this.currentItem.patientInitialSecond;
      this.checkPatientInitialDigit(initial, this.$refs.patientInitialSecondTextField);
    },
    handleChangeFormatedDate(value) {
      const dateTime = new Date(`${value} ${this.formatedTime}`);
      const { date } = date2Strings(dateTime);
      this.formatedDate = date;
    },
    handleChangeFormatedTime(value) {
      const dateTime = new Date(`${this.formatedDate} ${value}`);
      const { time } = date2Strings(dateTime);
      this.formatedTime = time;
    },
    /**
     * validation用
     */
    async checkDeviceId() {
      const textField = this.$refs['deviceIdTextField'];
      this.clearErrorMessages(textField);
      const deviceId = this.currentItem.deviceId;
      this.isNextGenerationEcg = false;

      // バリデーションがエラーの場合は終了
      if (!textField.validate()) return;

      // デバイスデータ取得
      let doc = null;
      try {
        doc = await this.$db.collection('devices').doc('T-' + deviceId).get();
        if (doc.exists) {
          let deviceData = doc.data();
          // 廃棄チェック
          if (deviceData.status == 9) {
            textField.errorMessages.push('廃棄済みのホルター心電計の番号です。');
            return;
          }
          // 「装着済み」チェック
          if (deviceData.status == 4
              || (deviceData.status == 7 && deviceData.patient_id != null)
              || (deviceData.status == 5 && deviceData.patient_id != null)
          ) {
            textField.errorMessages.push('患者様に装着済みのホルター心電計の番号です。');
            return;
          }
        }
      } catch (e) {
        console.warn(e);
        return;
      }

      // ※ 非同期で処理されるため、最後のスレッドで正常だとしても、
      //   前のスレッドでエラーだとそれが表示されてしまう
      //   そのため、最後にもエラークリアを入れる
      this.clearErrorMessages(textField);

      // 次期心電計（PX3.0〜）の判定
      this.isNextGenerationEcg = /^[A-Z][0-9A-Z]{6}$/.test(deviceId);
    },
    checkDigit(v) {
      let result = true;
      v.split('').forEach((digit) => {
        if (!parseInt(digit) && digit !== '0') {
          result = false;
        }
      });
      return result;
    },
    checkPatientInitialDigit(value, textField) {
      this.clearErrorMessages(textField);
      if (!value) return false;
      if (value.trim() === '') return false;
      if (!/^[a-zA-Z]{1}$/.test(value)) {
        textField.errorMessages.push('半角英字を1文字入力してください。');
        return false;
      }
      return true;
    },
    checkDuplicateCustomerStaff() {
      for (const staff of this.currentItem.customerStaffs) {
        const index = staff.index;
        const customerStaffSelect = this.$refs[`customerStaffSelect-${index}`][0];
        // 一旦エラーをクリア
        this.clearErrorMessages(customerStaffSelect);
        // 未選択の場合はスキップ
        if (staff.id == '') continue;
        // 全ての行から、自身と未選択の行を除外した配列を作成する
        const filteredStaffs = this.currentItem.customerStaffs.filter( v => v.id != '' && v.index != index );
        // 自身と同じ値が、フィルタされた行の中にないかチェック
        for (const v of filteredStaffs) {
          if (v.id == staff.id) {
            customerStaffSelect.errorMessages.push('重複した担当医が選択されています。');
            break;
          }
        }
      }
    },
    clearErrorMessages(field) {
      if (field && field.errorMessages) {
        field.errorMessages.splice(0);
      }
    },
    /**
     * form操作用
     */
    addCustomerStaffForm() {
      this.maxCustomerStaffIndex = this.maxCustomerStaffIndex + 1;
      this.currentItem.customerStaffs.push({
        index: this.maxCustomerStaffIndex,
        id: '',
        name: '未選択'
      });
    },
    deleteCustomerStaffForm(index) {
      this.currentItem.customerStaffs = this.currentItem.customerStaffs.filter(customerStaff => customerStaff.index !== index)
      if (this.currentItem.customerStaffs.length == 0) {
        this.currentItem.customerStaffs = [{
          index: 0,
          id: '',
          name: '未選択'
        }];
      }
      this.checkDuplicateCustomerStaff();
    },
    async setRegisterForm(item) {

      // 次期心電計（PX3.0〜）の判定
      if (item && item.deviceId) {
        this.isNextGenerationEcg = /^[A-Z][0-9A-Z]{6}$/.test(item.deviceId);
      }

      // データ初期設定
      let currentItem;
      if (
        (this.mode === 'edit' && (!item || this.beforeAnalysisOpinionType === null || this.isContractedAnalysisOption === null))
        || (this.mode === 'new' && (this.beforeAnalysisOpinionType === null || this.isContractedAnalysisOption === null))
      ) {
        // まだ親からデータを受け取っていないタイミングの場合
        return;
      } else if (this.mode === 'edit') {
        currentItem = item;
      } else {
        currentItem = this.createEmptyItem();
        if (item) {
          currentItem = item;
        }
      }
      
      // QRコードから遷移してきた場合、URIからデフォルト値を設定する
      if(this.mode === 'new' && this.$route.query.serial && this.$route.query.password){
        currentItem['deviceId'] = this.$route.query.serial;
        currentItem['password'] = this.$route.query.password;
      }

      // 解析オプションの設定
      if (this.mode === 'new') {
        // 新規登録の場合
        // オプション解析未契約の場合は自動解析、契約済みの場合は組織のデフォルト値にする
        currentItem['analysis_opinion_type'] = !this.isContractedAnalysisOption ? this.ANALYSIS_OPINION_TYPES.AUTO : this.beforeAnalysisOpinionType;

        // 22/07 技師解析CPに対する一時的な対応 ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
        if (!this.isContractedAnalysisOption && this.isTechCp2207) {
          // オプション解析未契約、かつ技師解析CP中の場合、技師解析を選択
          currentItem['analysis_opinion_type']  = this.ANALYSIS_OPINION_TYPES.TECHNICIAN;
        }
        // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

      } else if (this.status == 4 || this.status == 5) {
        // 使用中、又は受付センター着荷状態
        if (
          !this.isContractedAnalysisOption
          || !('analysis_opinion_type' in currentItem)
          || currentItem.analysis_opinion_type === null
        ) {
          // オプション解析未契約、又は解析オプション未設定（旧データ）の場合、自動解析にする
          currentItem['analysis_opinion_type'] = this.ANALYSIS_OPINION_TYPES.AUTO;
        }

        // 22/07 技師解析CPに対する一時的な対応 ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
        if (!this.isContractedAnalysisOption && this.isTechCp2207) {
          // オプション解析未契約、かつ技師解析CP中の場合、技師解析を選択
          currentItem['analysis_opinion_type']  = this.ANALYSIS_OPINION_TYPES.TECHNICIAN;
        }
        // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑
      } else if (this.status >= 6 && this.status != 8) {
        // 解析依頼後＆未使用期限切れ以外
        if (
          !('analysis_opinion_type' in currentItem)
          || currentItem.analysis_opinion_type === null
        ) {
          // 解析オプション未設定（旧データ）の場合、自動解析にする
          currentItem['analysis_opinion_type'] = this.ANALYSIS_OPINION_TYPES.AUTO;
        }
      }

      // 解析オプション変更履歴の設定
      this.anarysisOpinionTypeChangeHistory = '';
      if (
        'anarysis_opinion_type_change_history' in currentItem
        && currentItem.anarysis_opinion_type_change_history
      ) {
        // 履歴が存在している場合
        const datetime = dateformat(new Date(currentItem.anarysis_opinion_type_change_history.datetime.seconds * 1000), 'yyyy/mm/dd HH:MM:ss');
        let type = '';
        switch (currentItem.anarysis_opinion_type_change_history.type) {
          case 0:
            type = '自動解析';
            break;
          case 1:
            type = '技師解析';
            break;
          case 2:
            type = '医師所見';
            break;
        }
        this.anarysisOpinionTypeChangeHistory = `[ ${datetime}、${currentItem.anarysis_opinion_type_change_history.name}様によって「${type}」に変更されました。 ]`;
      }

      await this.setSelectListForCustomerStaffs();
      //const { date, time } = date2Strings(currentItem.patientAttached);
      this.formatedDate = dateformat(new Date(currentItem.patientAttached), 'yyyy-mm-dd');
      this.formatedTime = dateformat(new Date(currentItem.patientAttached), 'HH:MM:ss');

      const { formatedStaffs, maxIndex } = this.formatCustomerStaffs(currentItem.customerStaffs);
      this.maxCustomerStaffIndex = maxIndex;
      currentItem.customerStaffs = formatedStaffs;

      const patientInitial = currentItem.patientInitial.split(' ');
      currentItem.patientInitialFirst = '';
      currentItem.patientInitialSecond = '';
      if (patientInitial[0]) {
        currentItem.patientInitialFirst = patientInitial[0];
      }
      if (patientInitial[1]) {
        currentItem.patientInitialSecond = patientInitial[1];
      }

      this.currentItem = currentItem;
    },
    async setSelectListForCustomerStaffs() {
      let staffsRef = this.$db.collection('customer_staffs')
        .where('deleted', '==', null)
        .where('org_id', '==', this.$store.state.user.org_id);
      const staffsQuerySnapshot = await staffsRef.get();
      const staffs = [{
        text: '未選択',
        value: '',
      }];
      staffsQuerySnapshot.forEach((staffDoc) => {
        const staffData = staffDoc.data();
        staffs.push({
          text: staffData.name,
          value: staffDoc.id,
        });
      });
      this.customerStaffSelectItems = JSON.parse(JSON.stringify(staffs));
    },
    /**
     * データ整形用
     */
    createEmptyItem() {
      return {
        deviceId: '',
        patientAttached: new Date(),
        customerStaffs: [{
          index: 0,
          id: '',
          name: '未選択',
        }],
        age: null,
        sex: 0,
        patientInitial: '',
        patientInitialFirst: '',
        patientInitialSecond: '',
        remarks: '',
        examNumber: ''
      };
    },
    formatCustomerStaffs(staffs) {
      let maxIndex = 0;
      const formatedStaffs = [];
      staffs.forEach((staff) => {
        formatedStaffs.push({
          index: maxIndex,
          id: staff.id,
          name: staff.name,
        });
        maxIndex++;
      });
      if (formatedStaffs.length === 0) {
        formatedStaffs.push({
          index: 0,
          id: '',
          name: ''
        });
        maxIndex += 1;
      }
      return {
        formatedStaffs: formatedStaffs,
        maxIndex: maxIndex - 1,
      }
    },
    mergePatientInitial(first, second) {
      if (!first.length && !second.length) {
        return '';
      }
      return first + ' ' + second;
    },
    /**
     * submit
     */
    async confirmCurrentItem() {
      if (!this.$refs.form.validate()) return;

      if (!this.isContractedAnalysisOption) {
        // オプション解析が未契約の場合、強制的に自動解析にする
        this.currentItem.analysis_opinion_type = this.ANALYSIS_OPINION_TYPES.AUTO;

        // 22/07 技師解析CPに対する一時的な対応
        if (this.isTechCp2207) this.currentItem.analysis_opinion_type = this.ANALYSIS_OPINION_TYPES.TECHNICIAN;
      }

      if (
        this.beforeAnalysisOpinionType != this.currentItem.analysis_opinion_type
        && !(this.isTechCp2207 && !this.isContractedAnalysisOption)   // 22/07 技師解析CPに対する一時的な対応
      ) {
        // 解析オプションが変更されている場合
        if (
          this.currentItem.analysis_opinion_type === this.ANALYSIS_OPINION_TYPES.TECHNICIAN
          || this.currentItem.analysis_opinion_type === this.ANALYSIS_OPINION_TYPES.DOCTOR
        ) {
          // 変更後の解析オプションが技師解析、医師所見の場合
          let msg = [
            '解析/所見 で「技師解析」又は「医師所見」が選択されているため、解析時に追加料金が発生します。',
            '　',
            this.mode == 'edit' ? '更新してもよろしいですか？' : '登録してもよろしいですか？',
            '　',
            '※ デバイス受付センターで受付する前であれば変更できます',
          ];
          if (this.status > 6) {
            msg = [
              '解析/所見 で「技師解析」又は「医師所見」が選択されたため、再度解析を依頼します。',
              '　',
              '追加料金が発生し、キャンセルすることがきません！',
              '　',
              '更新してもよろしいですか？',
            ];
          }
          const result = await this.$root.$confirm_multi_line(`重要！`, msg);
          if (!result) return null;
        }
      }

      if (this.mode == 'new') {
        try {
          const result = await this.$functions.createPassword({ serialNumber: this.currentItem.deviceId });
          const serialNumberHashCode = result.data;
          const isSerialNumberHashCodeAndPasswordMatched = serialNumberHashCode === this.currentItem.password;
          if (!isSerialNumberHashCodeAndPasswordMatched) {
            this.$root.$alert('エラー', 'シリアル番号とパスワードが一致しません。');
            return;
          }
        } catch (error) {
          console.log(error);
        }
      }

      // フォームに入力されていない医者リストから削除
      this.currentItem.customerStaffs = this.currentItem.customerStaffs.filter(staff => staff.id !== '')
      // 選択されたIDだけ入力されている状態なので、IDを元に氏名も更新する
      this.currentItem.customerStaffs.forEach((staff, index) => {
        const item = this.customerStaffSelectItems.find((item) => item.value === staff.id);
        if (item) {
          this.currentItem.customerStaffs[index].name = item.text
        }
      });

      this.currentItem.patientInitial = this.mergePatientInitial(
        this.currentItem.patientInitialFirst ? this.currentItem.patientInitialFirst.trim() : '',
        this.currentItem.patientInitialSecond ? this.currentItem.patientInitialSecond.trim() : ''
      );

      this.$nextTick(async () => {
        await this.confirmCurrentItemBody();
      });
    },
    async confirmCurrentItemBody() {
      this.currentItem.patientAttached = `${this.formatedDate}T${this.formatedTime}+09:00`;

      if (new Date() < new Date(this.currentItem.patientAttached)) {
        await this.$root.$alert('エラー', '検査開始日時が未来の日時になっています。');
        return;
      }

      this.$emit('confirm', this.currentItem);
    },
    isExistInStaffs(id) {
      for (const staff of this.customerStaffSelectItems) {
        if (staff.value == id) return true;
      }
      return false;
    },
  },
  mounted() {
    console.log('PatientRegisterForm mounted');
    this.setRegisterForm(this.item);
  },
  watch: {
    async item(value) {
      await this.setRegisterForm(value);
    },
    async beforeAnalysisOpinionType() {
      await this.setRegisterForm(this.item);
    },
    async isContractedAnalysisOption() {
      await this.setRegisterForm(this.item);
    },
  }
}
</script>

<style>
.patient-initial {
  display: flex;
  position: relative;
}

.patient-initial .one-digit-charactor {
  flex: 0 1 1rem;
  margin-right: 1rem;
}

.patient-initial .v-text-field__details {
  position: absolute;
  white-space: nowrap;
  overflow: visible;
}

.patient-initial .first-char .v-text-field__details {
  top: 4.3rem;
}

.patient-initial .second-char .v-text-field__details {
  top: 3.3rem;
  left: 11.7rem;
}

.analysis-opinion-type .v-input--selection-controls {
  margin-top: 0;
}
</style>