<template>
  <v-card>
    <v-card-title class="primary white--text">完了</v-card-title>
    <v-card-text>
      <v-container>
        <div class="d-flex align-center justify-center flex-column">
          <div class="text-body-1 ma-4">登録が完了しました。下記の検査番号を控え用紙に記入してください。</div>
          <v-label>＜検査番号＞</v-label>
          <div class="text-h3 blue--text">{{ examNumber }}</div>
          <div class="text-body-1 ma-4 text-center">初期波形確認を行う場合は検査開始登録で登録した内容を入力してください。<br />被検者ID欄には検査番号を入力してください。</div>
        </div>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <div class="flex-grow-1"></div>
      <v-btn color="primary darken-1" text @click="handleClose">閉じる</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: 'PatientRegisterComplete',
  props: {
    item: Object,
  },
  data() {
    return {
      examNumber: ''
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
  mounted() {
    this.examNumber = '';
    if (this.item.examNumber) {
      this.examNumber = this.item.examNumber;
    }
  },
  watch: {
    item(value) {
      if (value.examNumber) {
        this.examNumber = value.examNumber;
      }
    }
  }
}
</script>